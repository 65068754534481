import gql from "graphql-tag";

export const LOGGED_IN_USER_DATA_FIELDS = `
  id
  first_name
  last_name
  user_type
  mobile
  email
  gender
  date_of_birth
  mobile_verified
  email_verified
  access_token {
    expires
    token
    refresh_token
  }
  stakeholder {
    ... on Candidate {
      id
      is_experienced_feedback
      current_company
      functional_areas {
        id
        name
        image
        full_image
      }
      fa_experience{
        id
        experience
        functional_area {
          id
          name
          image
          full_image
        }
      }
      expectation {
        notice_period
        preferred_cities {
          id
          name
        }
      }
      salary {
        id
        current_salary
        current_salary_format
        expected_salary
        expected_salary_format
        normalized_current_salary
      }
      work_experience {
        id
        functional_area {
          id
          name
        }
        job_title
        started_from
        worked_till
        till_now
      }
      educations {
        id
        institute
        place {
          location
          place_id
          short_formatted_address
        }
        proficiency_level
        year_of_passing
        degree
        specialization
      }
      address {
        id
        address_text
        locality {
          id
          name
          city{
            id
            name
          }
        }
      }
      profile_completion
      is_experienced
      total_experience
      resume
      public_resume
      is_staffing_employee
    }
  }
`;

export const LOGGED_IN_USER_DATA = gql`
  query userData {
    me {
      ${LOGGED_IN_USER_DATA_FIELDS}
    }
  }
`;

export const LOGGED_OUT_USER_DATA = gql`
  query userData($userId: ID!) {
    node(id: $userId, type: User) {
      ... on User {
        ${LOGGED_IN_USER_DATA_FIELDS}
      }
    }
  }
`;

const USER_DETAILS_WITH_TOKEN = gql`
  fragment UserDetailsWithToken on User {
    id
    first_name
    last_name
    mobile
    gender
    date_of_birth
    access_token {
      expires
      token
      refresh_token
    }
    stakeholder {
      ... on Candidate {
        id
        current_company
        is_experienced_feedback
        educations {
          id
        }
        functional_areas {
          id
        }
        fa_experience {
          id
        }
        address {
          id
          address_text
          locality {
            id
            city {
              id
            }
          }
        }
      }
    }
  }
`;

export const AUTO_LOGIN = gql`
  mutation UserAutoLogin(
    $username: String!
    $hash_code: String!
    $client_id: String!
  ) {
    userAutoLogin(
      username: $username
      hash_code: $hash_code
      client_id: $client_id
    ) {
      ...UserDetailsWithToken
    }
  }
  ${USER_DETAILS_WITH_TOKEN}
`;

export const CREATE_USER = gql`
  mutation createCandidate($payload: CandidateCreateInput!) {
    mutate(type: CandidateMutation) {
      ... on CandidateMutation {
        create(payload: $payload) {
          user {
            id
            first_name
            last_name
            mobile
            gender
            date_of_birth
            stakeholder {
              ... on Candidate {
                id
                is_experienced_feedback
                current_company
                educations {
                  id
                }
                functional_areas {
                  id
                }
                fa_experience {
                  id
                }
                address {
                  id
                  address_text
                  locality {
                    id
                    city {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const EDIT_CANDIDATE = gql`
  mutation editCandidate($payload: CandidateEditInput!, $id: ID!) {
    mutate(type: CandidateMutation, id: $id) {
      ... on CandidateMutation {
        edit(payload: $payload) {
          id
          total_experience
          user {
            id
            first_name
            last_name
          }
          is_experienced_feedback
          current_company
          functional_areas {
            id
            name
            image
            full_image
          }
          fa_experience {
            id
            experience
            functional_area {
              id
            }
          }
          educations {
            id
            institute
            place {
              location
              place_id
              short_formatted_address
            }
            proficiency_level
            year_of_passing
            degree
            specialization
          }
          expectation {
            preferred_cities {
              id
            }
          }
          salary {
            id
          }
          address {
            id
            address_text
            locality {
              id
              name
              latitude
              longitude
              city {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const USER_SEARCH = gql`
  query UserSearch($query: String!) {
    nodes(type: User, query: $query) {
      edges {
        ... on User {
          id
          first_name
          last_name
          user_type
          mobile
          email
          mobile_verified
          email_verified
          password_exists
          alternate_mobile
          verified_details {
            mobile
            verified_by_candidate
          }
        }
      }
    }
  }
`;

// ====================================================================================================================================================================================
//  ------------- Add user Email ------------------ //

export const ADD_USER_EMAIL = gql`
  mutation addUserEmail(
    $userId: ID!
    $newEmail: String
    $authToken: String
    $idToken: String
    $provider: SocialProvider
  ) {
    addUserEmail(
      user_id: $userId
      new_email: $newEmail
      social_auth_token: $authToken
      id_token: $idToken
      provider: $provider
    )
  }
`;

// --------------- add user mobile ----------------------------- //

export const ADD_USER_MOBILE = gql`
  mutation addUserMobile($userId: ID!, $newMobile: String!) {
    addUserMobile(user_id: $userId, new_mobile: $newMobile)
  }
`;

// -----------------Get Locality---------------------- //

export const GET_UTM = gql`
  query getUTMValue($old_utm: String!, $new_utm: String!) {
    getUpdatedUTM(old_utm: $old_utm, new_utm: $new_utm) {
      current_utm
      time_reset
    }
  }
`;

export const GET_LOCALITY = gql`
  query locationSearch(
    $queryType: LocalitySearchType!
    $Latitude: Float
    $Longitude: Float
    $query: String!
  ) {
    searchLocality(
      query_type: $queryType
      latitude: $Latitude
      longitude: $Longitude
      query: $query
    ) {
      id
      name
      city {
        id
        name
      }
    }
  }
`;

export const GET_LOCATION = gql`
  query locationSearch(
    $queryType: LocalitySearchType!
    $Latitude: Float
    $Longitude: Float
    $query: String
  ) {
    searchLocality(
      query_type: $queryType
      latitude: $Latitude
      longitude: $Longitude
      query: $query
    ) {
      id
      name
      city {
        id
        name
      }
    }
  }
`;
