import { Promise } from "core-js";
import { Dimensions, Platform } from "react-native";
import { getCookie, setCookie } from "root/lib/session";
import RouterObject from "root/routes";
import { isValidMobile } from "screens/common/utils";
import { GET_UTM } from "screens/LoginRegistration/queries";
import { graphQLQueryHelper } from "utils/graphQL";
import { mLog } from "utils/logger";
import { extractFilterFromQueryParams } from "utils/parseUrl";
import NavHistory from "utils/routing/NavHistory";

function ClevertapException(message) {
  this.message = message;
  this.name = "ClevertapException";
}

export const trackPageViewEvent = (page) => {
  // This is jsut a token method because correspondingly there is a method for android
};

const checkUrlLength = (url) => {
  let urlLength = "";
  if (url.length >= 1024) {
    urlLength = url.substring(0, 1023);
  } else {
    urlLength = url;
  }
  return urlLength;
};

const getDefaultProps = () => {
  const desktopWidth =
    Platform.OS === "web" && Dimensions.get("window").width < 800
      ? "Mobile Web"
      : "Desktop Web";
  const history = NavHistory.get();
  const referrer = history.length > 1 ? history[history.length - 2] : "";
  const match = RouterObject.match(window.location.href);
  const utmCookies = [
    "utm_source",
    "utm_page",
    "utm_medium",
    "utm_campaign",
    "http_referer",
    "utm_term",
    "utm_content",
    "gclid"
  ];
  const utmValues = utmCookies.map((name) => getCookie(name));
  const utmObject = utmCookies.reduce((obj, name, index) => {
    // eslint-disable-next-line
    obj[name] = utmValues[index];
    return obj;
  }, {});
  // const utmCookie = getCookie("utm_source");
  const returnObject = {
    ...utmObject,
    Platform: desktopWidth,
    State: document.cookie.indexOf("loginaj") > 0 ? "Logged In" : "Logged Out",
    Current_Page: match.route ? match.route.name : "Unknown",
    URL: window.location.href,
    Referrer_Page: referrer ? referrer.route || "" : "NA",
    Referrer_URL: referrer
      ? checkUrlLength(referrer.url)
      : checkUrlLength(document.referrer)
      ? checkUrlLength(document.referrer)
      : "NA"
  };
  return returnObject;
};

const getDefaultUTMProps = () => {
  const utmCookies = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "http_referer",
    "utm_term",
    "utm_content",
    "gclid"
  ];
  const utmValues = utmCookies.map((name) => getCookie(name));
  const utmObject = utmCookies.reduce((obj, name, index) => {
    // eslint-disable-next-line
    obj[name] = utmValues[index];
    return obj;
  }, {});
  // const utmCookie = getCookie("utm_source");
  const returnObject = {
    ...utmObject
  };
  return returnObject;
};

export const setCurrentAndPreviousScreenFromNavigation = (cScreen, pScreen) => {
  // This is jsut a token method because correspondingly there is a method for android
};

export const setStateLoggedInOrNot = (isLoggedInOrNot) => {
  // This is jsut a token method because correspondingly there is a method for android
};

export const trackEvent = (eventName = "", data = {}, eventType = "") => {
  // This is jsut a token method because correspondingly there is a method for android
};

export const pushUTMEvent = () => {
  const mergedProps = { ...getDefaultUTMProps() };
  return mergedProps;
};

export const pushClevertapEvent = (event, eventProps) => {
  if (typeof window !== "undefined" && window.clevertap) {
    const defaultProps = getDefaultProps();
    storeUTMParameters().then(() => {
      const mergedProps = { ...defaultProps, ...eventProps };

      mLog("Push CleverTap Event", event, mergedProps);
      try {
        clevertap.event.push(event, mergedProps);
      } catch (e) {
        mLog("Clevertap Exception: ", e);
        throw new ClevertapException(e);
      }
    });
  }
};

export const pushClevertapOnUserLogin = (userProps) => {
  if (typeof window !== "undefined" && window.clevertap) {
    let siteObj = {
      Name: userProps.Name,
      Identity: userProps.Identity,
      Email: userProps.Email
    };

    if (isValidMobile(userProps.Phone)) {
      siteObj = { ...siteObj, Phone: userProps.Phone };
    }

    mLog("Push CleverTap Profile", siteObj);

    try {
      clevertap.onUserLogin.push({
        Site: siteObj
      });
    } catch (e) {
      mLog("Clevertap Exception: ", e);
      throw new ClevertapException(e);
    }
  }
};

export const pushClevertapProfile = (userProps) => {
  if (typeof window !== "undefined" && window.clevertap) {
    mLog("Push CleverTap Profile", userProps);
    try {
      clevertap.profile.push({
        Site: {
          Fa: [userProps.Fa],
          InterestedInLogistics: userProps.InterestedInLogistics,
          InterestedInLogistics: userProps.InterestedInLogistics,
          Name: userProps.Name,
          Identity: userProps.Identity,
          Email: userProps.Email,
          Gender: userProps.Gender,
          DOB: userProps.DOB,
          "Candidate FA1": userProps.Candidate_FA1,
          "Candidate FA2": userProps.Candidate_FA2,
          "Candidate FA3": userProps.Candidate_FA3,
          "Experience FA1": userProps.Experience_FA1,
          "Experience FA2": userProps.Experience_FA2,
          "Experience FA3": userProps.Experience_FA3,
          "Candidate Education": userProps.Candidate_Education,
          "Candidate Total Work Exp": userProps.Candidate_Total_Work_Exp,
          "Candidate Locality": userProps.Candidate_Locality,
          "Candidate City": userProps.Candidate_City,
          "Application count": userProps.application_count,
          Language: userProps.Language,
          ...userProps.siteObj
        }
      });
    } catch (e) {
      mLog("Clevertap Exception: ", e);
      throw new ClevertapException(e);
    }
  }
};

export const pushClevertapUserProperties = (props) => {
  if (typeof window !== "undefined" && window.clevertap) {
    mLog("Push CleverTap User Properties", props);
    try {
      clevertap.profile.push({
        Site: {
          ...props
        }
      });
    } catch (e) {
      mLog("Clevertap Exception: ", e);
      throw new ClevertapException(e);
    }
  }
};

const storeUTMParameters = () => {
  // Since SSR caching also caches props, params are extracted from URL

  const queryParamsString = window ? window.location.search.slice(1) : "";
  const { filterObject } = extractFilterFromQueryParams(queryParamsString);

  const utmCookies = [
    "utm_source",
    "utm_page",
    "utm_medium",
    "utm_campaign",
    "http_referer",
    "utm_term",
    "utm_content",
    "gclid"
  ];

  const promises = [];

  if (utmCookies.filter((name) => name in filterObject).length > 0) {
    if (filterObject.utm_source && filterObject.utm_source[0]) {
      const getExistingCookie = getCookie("utm_source");
      if (
        getExistingCookie !== undefined &&
        getExistingCookie !== filterObject.utm_source[0]
      ) {
        const newPromise = getUTMValue(
          filterObject.utm_source[0],
          getExistingCookie
        ).then((getValue) => {
          if (getValue && getValue !== getExistingCookie) {
            setUTMCookiesValue(utmCookies, filterObject, true);
          } else {
            setUTMCookiesValue(utmCookies, filterObject, false);
          }
        });
        promises.push(newPromise);
      } else {
        setUTMCookiesValue(utmCookies, filterObject, true);
      }
    }
  }
  return Promise.all(promises);
};
const setUTMCookiesValue = (utmCookies, filterObject, setNewCookie) => {
  utmCookies.forEach(async (name) => {
    const existingCookieValue = getCookie(name);
    let filterItem = filterObject[name];
    if (filterObject[name] instanceof Array) {
      filterItem = filterObject[name][0];
    }
    if (setNewCookie === true) {
      setCookie(name, filterItem || "", undefined, { expires: 30 });
    } else {
      setCookie(name, existingCookieValue || "", undefined, { expires: 30 });
    }
  });
};

const getUTMValue = async (filterItem, existingCookieValue, ...restProps) => {
  const client = window.__APOLLO_CLIENT__;
  const { data, error } = await graphQLQueryHelper(client)({
    query: GET_UTM,
    variables: {
      old_utm: existingCookieValue,
      new_utm: filterItem
    }
  });

  if (data) {
    return data.getUpdatedUTM.current_utm;
  }

  mLog("Error in detting UTM", error);
  return null;
};

export const getClevertapID = () => {};

// export const trackEvent = () => {};

export const addCleverTapNativeListener = () => {
  // This is jsut a token method because correspondingly there is a method for android
};

export const removeCleverTapNativeListener = () => {
  // This is jsut a token method because correspondingly there is a method for android
};

export const pushNativeDisplayViewedEvent = (unitID) => {
  // This is jsut a token method because correspondingly there is a method for android
};

export const pushNativeDisplayClickedEvent = (unitID) => {
  // This is jsut a token method because correspondingly there is a method for android
};


export const updateUserProfilPicForCleverTap = (image) => {
  // This is jsut a token method because correspondingly there is a method for android
};